<template>
  <div
    class="hero-swipeable"
    :class="[`mobile-format--${mobileSize}`, `desktop-format--${desktopSize}`]"
  >
    <div ref="heroSwipeableSlider" class="hero-swipeable__slider">
      <component
        :is="hero.component"
        v-for="(hero, i) in heroes"
        :key="`hero-swipeable__slider__hero${_uid}${i}`"
        ref="heroSwipeableSliderHero"
        v-editable="hero"
        v-bind="hero"
        :index="i"
        class="hero-swipeable__slider__hero"
        @hook:mounted="observeHeroes(i)"
      />
    </div>
    <div class="hero-swipeable__indicator">
      <div
        v-for="i in heroes.length"
        :key="`hero-swipeable__indicator__dot${_uid}${i}`"
        class="hero-swipeable__indicator__dot"
        :class="activeClass(i - 1)"
        @click="() => scrollTo(i - 1)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeroSwipeable',
  props: {
    heroes: {
      type: Array,
      default: () => [],
    },
    desktopSize: {
      type: String,
      default: '',
    },
    mobileSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      intersectionObserver: null,
      activeIndex: 0,
    }
  },
  mounted() {
    this.initIntersectionObserver()
  },
  methods: {
    initIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        this.intersectionHandler,
        { root: this.$refs.heroSwipeableSlider, threshold: 0.9 }
      )
    },
    intersectionHandler(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.activeIndex = entry.target.__vue__.index
        }
      })
    },
    observeHeroes(i) {
      // start observeing when the last Hero has mounted
      if (i === this.heroes.length - 1)
        this.$refs.heroSwipeableSliderHero.forEach((hero) => {
          this.intersectionObserver?.observe(hero.$el)
        }, this)
    },
    scrollTo(i) {
      this.$refs.heroSwipeableSlider.scrollTo({
        left: this.$refs.heroSwipeableSliderHero[i].$el.offsetLeft,
        behavior: 'smooth',
      })
    },
    activeClass(i) {
      if (this.activeIndex === i) return 'active'
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
.hero-swipeable {
  position: relative;

  .hero-swipeable__slider {
    @include hide-scrollbars;

    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .hero-swipeable__slider__hero {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    scroll-snap-align: start;

    ::v-deep {
      .ratio {
        width: 100%;
        height: 100%;
      }
    }
  }

  .hero-swipeable__indicator {
    position: absolute;
    bottom: spacing('small');
    width: 100%;
    display: flex;
    justify-content: center;
    gap: spacing('small');
  }

  .hero-swipeable__indicator__dot {
    width: spacing('medium');
    height: spacing('medium');
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    border-radius: 100%;
    cursor: pointer;
    mix-blend-mode: multiply;

    &::before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background-color: $blue;
    }

    &.active {
      opacity: 1;
    }
  }
}
</style>
